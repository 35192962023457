import AccessControl from "../images/access_control.png";
import Fidelity from "../images/fidelity.png";
import VDS from "../images/vds.png";
import ProtenusApp from "../images/protenus_app.png";

export const projects: Project[] = [
  {
    company: "Protenus",
    description:
      "Worked on the core UI of the platform which surfaces employee / patient privacy violations and pharmacy drug diversion using artificial intelligence.",
    technologies:
      "React.js, Jest, Next.js, REST APIs, Agile Methodologies, CI/CD, Chakra, Cypress, TypeScript",
    imgSrc: ProtenusApp,
  },
  {
    company: "Storable",
    description:
      "Worked on Access Control which simplifies brick and mortar operations by empowering owners with tools to remotely perform and automate their job; interfacing with on-prem IoT devices.",
    technologies:
      "Micro Frontend architecture, React, Javascript, .NET, Cypress, Jest, Postgres, Docker, Kubernetes, Kafka",
    imgSrc: AccessControl,
  },
  {
    company: "Pendo",
    description:
      "Created features for an editor that enabled the deployment of user guidance while capturing analytics and steering user interactions. Also worked across the entire platform; modernizing pages and building out features like the Resource Center.",
    technologies: "Vue, Node.js, Javascript, Jest, Cypress, HTML, CSS",
    imgSrc: VDS,
  },
  {
    company: "Fidelity Investments",
    description:
      "Conducted A/B testing to optimize engagement metrics for a high-volume application. Prototyped and developed an HSA education platform with React.",
    technologies: "React, HTML, CSS, Javascript, Experimentation, KPI setting",
    imgSrc: Fidelity,
  },
];
