import Container from "@mui/material/Container";
import { projects } from "../data/projects";
import { FeaturedWork } from "../components/FeaturedWork";
import { Box, Link, Typography } from "@mui/material";

export const Home = () => {
  return (
    <main>
      <Container>
        <Box my="2em">
          <Typography variant="h4" mt="4em" mb="0.5em" sx={{ fontWeight: 700 }}>
            Hello, I'm Brandon - A Software Engineer based out of Colorado
          </Typography>
          <Typography variant="body1">
            I am a seasoned software engineer with over 8 years of experience
            delivering impactful solutions across diverse industries. I&apos;ve
            developed features for high-volume applications at Fidelity,
            improved UI/UX across the Pendo platform, implemented Micro
            Frontends at Storable. Currently, I&apos;m building the frontend for
            Protenus&apos;s healthcare compliance analytics platform.
          </Typography>
          <Box mt="1em">
            <Link
              href="mailto:jonesbrandonsean@gmail.com"
              sx={{
                color: "black",
                padding: "2px",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              jonesbrandonsean@gmail.com
            </Link>
          </Box>
        </Box>
        <FeaturedWork projects={projects} />
      </Container>
    </main>
  );
};
