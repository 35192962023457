import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";

export const ProjectCard = ({ project }: { project: Project }) => (
  <Card
    sx={{ minWidth: "500px", maxWidth: "500px", margin: "10px" }}
    variant="outlined"
  >
    <CardContent>
      <Typography sx={{ fontSize: 18 }} variant="h5" fontWeight={600}>
        {project.company}
      </Typography>
      <Typography sx={{ fontSize: 16 }}>{project.description}</Typography>
      <Box sx={{ marginTop: "10px" }}>
        <img src={project.imgSrc} alt="product" width="375vh" height="250vh" />
      </Box>
      <Typography sx={{ fontSize: 16 }}>{project.technologies}</Typography>
    </CardContent>
  </Card>
);
