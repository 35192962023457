import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ProjectCard } from "../components/ProjectCard";

export const FeaturedWork = ({ projects }: { projects: Project[] }) => (
  <Box mt="12em" mb="6em">
    <Typography variant="h5" sx={{ fontWeight: 700 }}>
      Featured Work
    </Typography>
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {projects.map((project, i) => (
        <ProjectCard key={i} project={project} />
      ))}
    </Box>
  </Box>
);
